import React from "react"
import styled from "styled-components"

import Button from "./index"

const WrapperPlus = styled.div`
  padding: 0 10px;
  position: relative;
  span {
    display: block;
    height: 2px;
    width: 20px;
    background-color: ${props =>
      props.inverse ? props.theme.primary : props.theme.white};
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:nth-child(2) {
      width: 2px;
      height: 20px;
    }
  }
`

const ButtonPlus = ({ inverse }) => (
  <Button
    colors={inverse ? "primary" : "primaryWithBg"}
    as="button"
    icon={
      <WrapperPlus inverse={inverse}>
        <span />
        <span />
      </WrapperPlus>
    }
  />
)

export default ButtonPlus

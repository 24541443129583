import { keyframes } from "styled-components"

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

export const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(82, 145, 206, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(82, 145, 206, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(82, 145, 206, 0);
  }
`

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner, { WrapperButton } from "../components/elements/Banner"
import FormationsBloc from "../components/Formations"
import Contact from "../components/Home/Contact"
import Thematiques from "../components/Formations/thematiques"
import Button from "../components/elements/Button"
import ButtonAnchor from "../components/elements/Anchor/ButtonAnchor"
import globalVariables from "../components/globalVariables"

const Formation = ({ data, location }) => {
  const { formations, allFormations, bloc_contact } = data
  return (
    <Layout
      location={location.pathname.split("/")[1]}
      locationBis={location.pathname}
      crumbLabel="Nos Métiers"
      crumbLabelBis="Formation"
      locationGTM={location.href}
    >
      <SEO
        title={formations.acf.title}
        description={formations.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <Banner
        imageBanner={
          formations.acf.image_banniere_new.localFile.childImageSharp.fluid
        }
        title={formations.acf.titre_banniere}
        description={formations.acf.texte_banniere}
        hashtag={formations.acf.hashtag}
        anchor="formation-bloc-1"
      >
        <WrapperButton>
          <ButtonAnchor
            anchor="nos-formations"
            offset={globalVariables.navbarHeight}
            title="Nos formations"
            colors="borderPrimaryHoverPrimary"
          />
          <Button
            as="link"
            title="Contactez-nous"
            to="/contact"
            colors="borderSecondaryHoverSecondary"
          />
        </WrapperButton>
      </Banner>
      <FormationsBloc
        image1={formations.acf.image_1.localFile.childImageSharp.fluid}
        image2={formations.acf.image_2.localFile.childImageSharp.fluid}
        title1={formations.acf.titre_1}
        title2={formations.acf.titre_2}
        text1={formations.acf.texte_1}
        text2={formations.acf.texte_2}
      />
      <Thematiques
        title={formations.acf.titre_thematique}
        text={formations.acf.texte_thematique}
        cta
        formations={allFormations.edges}
        padding="45px 0 0"
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        id="contact-form"
        location={location.href}
      />
    </Layout>
  )
}

export default Formation

export const query = graphql`
  query {
    formations: wordpressPage(
      id: { eq: "bfcccf69-9575-55f8-b8e3-898f361685d9" }
    ) {
      acf {
        title
        meta_description
        titre_1
        texte_1
        titre_2
        texte_2
        image_1 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_2 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_thematique
        texte_thematique
        image
        titre_banniere
        texte_banniere
        hashtag
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allFormations: allWordpressWpFormations {
      edges {
        node {
          acf {
            titre_partie_1_banniere
            titre_partie_2_banniere
            texte_thematique_vignette
          }
          path
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import getButtonStyled from "../Button/style"
import { DeviceContext } from "../DeviceProvider"
import globalVariables from "../../globalVariables"

const AnchorStyled = styled(AnchorLink)`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  ${getButtonStyled}
  cursor: pointer;
`

const ButtonAnchor = ({ anchor, title, children }) => {
  return (
    <DeviceContext.Consumer>
      {deviceValues => {
        return deviceValues.width ? (
          deviceValues.windowWidth < 1024 ? (
            <AnchorStyled href={"#" + anchor}>
              <span className="btn-text">{title}</span>
              {children}
            </AnchorStyled>
          ) : (
            <AnchorStyled
              href={"#" + anchor}
              offset={globalVariables.navbarHeight}
            >
              <span className="btn-text">{title}</span>
              {children}
            </AnchorStyled>
          )
        ) : null
      }}
    </DeviceContext.Consumer>
  )
}

export default ButtonAnchor

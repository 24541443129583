import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parser from "html-react-parser"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import Anchor from "../../elements/Anchor"

import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  & > div {
    height: 100%;
  }
`

const WrapperText = styled.div`
  @media (min-width: ${globalVariables.minTablet}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (min-width: ${globalVariables.minTablet}) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`
const Title = styled.h1`
  color: ${props => props.theme.primary};
  text-transform: uppercase;
  font-weight: 800;
  font-size: 34px;
  line-height: 50px;
  position: relative;
  margin-bottom: 5px;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 45px;
  }
`

const Hashtag = styled.h2`
  color: ${props => props.theme.bodyColor};
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 10px;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 36px;
  }
`

const Text = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  p,
  a span {
    color: ${props => props.theme.bodyColor};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    max-width: ${props => (props.maxWidth ? props.maxWidth : "75%")};
  }
`

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2rem;
  a {
    min-width: 250px;
  }
  a:first-child {
    margin-bottom: 1rem;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    padding-bottom: 0;
    a {
      min-width: 165px;
    }
    a:nth-child(2n) {
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }
`

const WrapperAnchor = styled.div`
  display: none;
  img {
    width: 30px;
    height: 30px;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Banner = ({
  title,
  description,
  imageBanner,
  hashtag,
  zIndex,
  noUnderline,
  maxWidth,
  className,
  paddingContent,
  anchor,
  children,
}) => {
  return (
    <Section
      padding="0"
      imageHeight={`calc(100vh - ${globalVariables.navbarHeightMobile} - 50px)`}
      imageMobileHeight={`calc(100vh - ${globalVariables.navbarHeight})`}
      isfirstBlockOnMobile
      zIndex={zIndex}
    >
      <Img fluid={imageBanner} />
      <Wrapper>
        <Container>
          <Content className={className} paddingContent={paddingContent}>
            <WrapperText>
              <Title noUnderline={noUnderline}>{title}</Title>
              {hashtag && <Hashtag>{hashtag}</Hashtag>}
              {description && (
                <Text noUnderline={noUnderline} maxWidth="800px">
                  {Parser(description)}
                </Text>
              )}
            </WrapperText>
            {children}
          </Content>
        </Container>
      </Wrapper>
      {anchor && (
        <WrapperAnchor>
          <Anchor anchor={anchor} offset={globalVariables.navbarHeight} />
        </WrapperAnchor>
      )}
    </Section>
  )
}

export default Banner

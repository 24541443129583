import React from "react"
import styled from "styled-components"
import Link from "gatsby-link"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Container from "../elements/Container"
import Title, { TitleH2 } from "../elements/Title"
import ButtonPlus from "../elements/Button/ButtonPlus"
import globalVariables from "../globalVariables"

const Section = styled.section`
  position: relative;
  padding: ${props => (props.padding ? props.padding : "45px 0")};
  background-color: ${props => props.theme.greyLighter};
  z-index: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 80px 0 105px;
    overflow: hidden;
  }
`

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 660px;
  margin: auto;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0 0 60px;
  }
`

const WrapperThematiques = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > div {
      flex: 0 0 33.33%;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    & > div {
      flex: 0 0 24.9%;
    }
  }
`

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  text-decoration: none;
  background: white;
  margin: 12.5px;
  position: relative;
  background: ${props =>
    props.isHighlight ? props.theme.primary : props.theme.white};
  height: 118px;
  box-shadow: ${props =>
    !props.isHighlight ? "0 8px 10px 0 rgba(0, 0, 0, 0.1)" : null};
  .title2 {
    margin-bottom: 10px;
    color: ${props =>
      props.isHighlight ? props.theme.white : props.theme.bodyColor};
  }
  .title1 {
    margin: 0;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    height: 160px;
  }
`

const CardHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 16px 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Card}:hover & {
    opacity: 1;
  }
`

const CardText = styled.p`
  margin-bottom: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    padding: 0;
    text-align: center;
    color: ${props =>
      props.isHighlight ? props.theme.white : props.theme.bodyColor};
  }
`

const WrapperPlus = styled.div`
  text-align: center;

  @media (max-width: ${globalVariables.maxMobile}) {
    & > button {
      width: 40px;
      height: 40px;
      min-width: auto;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 12px;
    & > button {
      width: 50px;
      height: 50px;
      min-width: auto;
    }
  }
`

const AnchorStyled = styled(AnchorLink)`
  color: white !important;
  text-transform: uppercase;
  font-size: 22px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
  }
`
const Mobile = styled.div`
  & > div {
    margin: 2rem 0 0;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    display: none;
  }
`

const Thematiques = ({ title, text, cta, formations, padding }) => {
  return (
    <Section padding={padding} id="nos-formations">
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        {text && <Text>{text}</Text>}

        <WrapperThematiques>
          {formations.map((item, index) => (
            <div key={index}>
              <Card to={item.node.path}>
                <Title className="title2" position="center">
                  {item.node.acf.titre_partie_1_banniere}
                </Title>
                <Title className="title1" position="center" color="primary">
                  {item.node.acf.titre_partie_2_banniere}
                </Title>
                <CardHover>
                  <CardText>{item.node.acf.texte_thematique_vignette}</CardText>
                  <WrapperPlus>
                    <ButtonPlus />
                  </WrapperPlus>
                </CardHover>
              </Card>
            </div>
          ))}

          {cta && (
            <Desktop>
              <Card isHighlight as="div">
                <AnchorStyled
                  href="#contact-form"
                  offset={globalVariables.navbarHeight}
                >
                  Demander une formation personnalisée
                </AnchorStyled>
              </Card>
            </Desktop>
          )}
        </WrapperThematiques>
      </Container>
      {cta && (
        <Mobile>
          <Card isHighlight as="div">
            <AnchorStyled
              href="#contact-form"
              offset={globalVariables.navbarHeight}
            >
              Demander une formation personnalisée
            </AnchorStyled>
          </Card>
        </Mobile>
      )}
    </Section>
  )
}

export default Thematiques

import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import AnchorIcon from "../../../images/icons/scroll-bas.svg"
import { pulse } from "../Keyframes"

const AnchorStyled = styled(AnchorLink)`
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;

  img {
    cursor: pointer;
    z-index: 1;
    animation: ${pulse} 2s infinite;
    border-radius: 10px;
    display: block;
  }
  &:hover {
    img {
      animation: none;
    }
  }
`

const Anchor = ({ anchor, offset }) => {
  return (
    <AnchorStyled href={"#" + anchor} offset={offset}>
      <img src={AnchorIcon} alt="ancre SLAP digital" />
    </AnchorStyled>
  )
}

export default Anchor

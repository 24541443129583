import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parser from "html-react-parser"

import globalVariables from "../globalVariables"
import Section from "../elements/Section"
import { TitleH2 } from "../elements/Title"
import ButtonAnchor from "../elements/Anchor/ButtonAnchor"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const WrapperImage = styled.div`
  /* display: none; */
  position: relative;
  @media (max-width: ${globalVariables.maxMobile}) {
    order: 1;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    flex: 1 0 50%;
  }
`

const Intro = styled.div`
  background-color: ${props => props.theme[props.color]};
  padding: 50px 20px;
  border-top: 1px solid white;
  @media (max-width: ${globalVariables.maxMobile}) {
    order: 2;
  }

  @media (min-width: ${globalVariables.minTablet}) {
    border-top: none;
    flex: 1 0 50%;
    padding: 90px 65px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title1.trait::after {
      bottom: -34px;
    }
  }
`

const Text = styled.div`
  p {
    color: white;
  }
  margin-top: 50px;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 50px;
    max-width: 500px;
  }
`
const WrapperButton = styled.div`
  margin-top: 2rem;
`

const FormationsBloc = ({ image1, image2, title1, title2, text1, text2 }) => {
  return (
    <Section padding="0" id="formation-bloc-1">
      <Container>
        <WrapperImage>
          <Img fluid={image1} />
        </WrapperImage>
        <Intro color="primary">
          <TitleH2 color="white" className="title1 trait" traitLeft="-65px">
            {title1}
          </TitleH2>
          <Text>{Parser(text1)}</Text>
        </Intro>
      </Container>
      <Container>
        <Intro color="secondary">
          <TitleH2
            color="white"
            className="title1 trait"
            traitLeft="-65px"
            traitColor="primary"
          >
            {title2}
          </TitleH2>
          <Text>{Parser(text2)}</Text>
          <WrapperButton>
            <ButtonAnchor
              anchor="contact-form"
              offset={globalVariables.navbarHeight}
              title="Demander une proposition de formation personnalisée"
              colors="primaryWithBg"
            />
          </WrapperButton>
        </Intro>
        <WrapperImage>
          <Img fluid={image2} />
        </WrapperImage>
      </Container>
    </Section>
  )
}

export default FormationsBloc
